.d-nav-link{
    font-size: 14px;
    font-weight: 700;
}

.table tr{
    cursor: pointer;
}

.scroll_table2 .MuiPaper-root{
    overflow: visible;
}

.scroll_table2 .css-nhjqqh-MuiTableContainer-root, .scroll_table2 .css-13sfx72{
    overflow: visible!important;
}

.loader {
    border: 1px solid #f3f3f3;
    border-radius: 50%;
    border-top: 2px solid #00bc8b;
    width: 12px;
    height: 12px;
    -webkit-animation: spin 2s linear infinite;
    /* Safari */
    animation: spin 2s linear infinite;
}

.loader-big {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: transparent;
    border-radius: 50%;
}

.loader-big {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: transparent;
    width: 36px;
    height: 36px;
}

.loader-big {
    border: 4px solid #01d59b;
    border-left-color: transparent;
    width: 36px;
    height: 36px;
    animation: spin89345 1s linear infinite;
}

.loader-center {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.loader-center-2 {
    display: flex;
    align-items: center;
    justify-content: center;
    
}


.d-nav-link {
    /* padding: 10px; */
    cursor: pointer;
    color: #fff;
    text-decoration: none;
}
.MuiBox-root.css-svewtb{
    display: none!important;
}


.d-nav-link.active {
    /* background-color: green; */
    
    /* color: white; */
    border-radius: 5px;
}

.d-nav-link.active a {   
    color: #37ffb5;
    border-radius: 5px;
}


.d-nav-link:hover {
    /* background-color: rgba(0, 255, 0, 0.5); */
    
    border-radius: 5px;
}
/* .css-h2wjal-MuiTableRow-root, .css-1jso7dy-MuiTableRow-root, .css-h2wjal-MuiTableRow-root td, .css-h2wjal-MuiTableRow-root:hover tr{
    background-color: #090c10!important;
} */

@keyframes spin89345 {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/* Safari */
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}