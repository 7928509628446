/* General table styling */
.scroll_table {
    overflow-x: auto;
}

.table td {
    transition: color 100ms ease-in-out;
    color: white;
    /* Default color, can be changed to any neutral color */
}

/* Color change based on updates */
td.green {
    color: green;
}

td.red {
    color: red;
}

/* Animation for transitions */
.data-change-enter {
    background-color: yellow;
}

.data-change-enter-active {
    background-color: white;
    /* Ensure background returns to white */
    transition: background-color 100ms ease-in-out;
}

/* .min-height{
    min-height: 287px;
    max-height: 304px;
        overflow: hidden;
} */

.selected {
    background-color: #242f3e;
}

.table td, .table th{
    text-align: left;
}


/* Container for the scrollable table */
.scroll_table {
    max-height: 250px;
    /* Adjust this height based on your requirements */
    overflow-y: auto;
    display: block;
    position: relative;
}

/* Make the table header fixed */
.table th {
    position: sticky;
    top: 0;
    background-color: #343a40;
    /* This ensures the header color matches your theme */
    z-index: 2;
    /* Ensure the header stays on top when scrolling */
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
    /* Optional: Shadow to distinguish header */
}

/* Optional styling for table header */
.table th.text-start {
    text-align: left;
    /* Keep this aligned to the left */
}

/* For a smooth scroll table */
/*  */

